import styled from "styled-components";

export const ProjectLink = styled.div`
  letter-spacing: 2px;
`;

export const shortDescription = styled.div`
  padding-top: 2rem;
`;

export const ContentContainer = styled.div`
  padding-top: 2rem;
  ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  li {
    width: 45%;
  }

  @media (max-width: 640px) {
    ul {
      flex-direction: column;
    }
    li {
      width: 100%;
      padding-bottom: 2rem;
    }
  }

  .imageContainer p {
    margin: 0;
  }
`;

export const ContactContainer = styled.div`
  padding-top: 4rem;
`;

export const ImageContainer = styled.div`
  width: 100%;
  p {
    margin: 0;
  }
`;
