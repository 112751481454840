import React from "react";
import Container from "../components/Container";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import { BackIcon, LinkIcon } from "../components/Icons";
import { Link, graphql } from "gatsby";
import TemplateWrapper from "../layouts/";

import {
  ContentContainer,
  ContactContainer,
  ImageContainer,
} from "./styled";

const BlogPostTemplate = ({ data }) => {
  const html = data.markdownRemark.html;
  const {
    link,
    companyName,
    shortDescription,
    workType,
    year,
  } = data.markdownRemark.frontmatter;
  const { footer, contact } = data.site.siteMetadata.content;

  let postHtml = html;

  const ProjectLink = () => (
    <div
      style={{
        paddingBottom: "2rem",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          fontFamily: "Gilroy-Bold",
        }}
      >
        <div
          style={{
            paddingBottom: "2rem",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            height: "40px",
          }}
        >
          <div style={{ paddingRight: "20px" }}>Ansehen</div>
          <LinkIcon fill="#FAFAFA" stroke="#FAFAFA"/>
        </div>
      </a>
    </div>
  );

  let screenshotHtml = "";
  if (postHtml.includes("%BEGIN-SCREENSHOTSCONTAINER%")) {
    screenshotHtml = postHtml.split("%BEGIN-SCREENSHOTSCONTAINER%")[1];
    postHtml = postHtml.split("%BEGIN-SCREENSHOTSCONTAINER%</p>")[0] + "</p>";
  }

  return (
    <TemplateWrapper>
      <div>
        <Container>
          <Link
            to="/#work"
            style={{
              paddingBottom: "40px",
            }}
          >
            <div
              style={{
                display: "inline-flex",
                alignItems: "center",
                border: "2px solid #ffffff",
                padding: "10px 20px",
                borderRadius: "2px",
                fontFamily: "Gilroy-Bold",
              }}
            >
              <BackIcon />
              <div style={{ paddingLeft: "20px" }}>Zurück</div>
            </div>
          </Link>
        </Container>
        <Container>
          <ContentContainer>
            <h3>
              {year} / {workType}
            </h3>
            <h2 style={{ paddingTop: "2rem" }}>{companyName}</h2>
            <h4>{shortDescription}</h4>
            <div
              dangerouslySetInnerHTML={{ __html: postHtml }}
              style={{ paddingTop: "2rem" }}
            />
            <ProjectLink />
          </ContentContainer>
        </Container>
        <Container>
          <ImageContainer // Screenshot Modul
            style={{
              maxWidth: "100%",
              width: "100",
            }}
            dangerouslySetInnerHTML={{ __html: screenshotHtml }}
          />
        </Container>
        <ContactContainer>
          <Contact data={contact} />
          <Footer data={footer} />
        </ContactContainer>
      </div>
    </TemplateWrapper>
  );
};
export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        content {
          contact {
            title
            headline
            subheadline
          }
          footer {
            left
            center
            imprint
          }
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        id
        link
        companyName
        color
        shortDescription
        workType
        year
      }
    }
  }
`;
